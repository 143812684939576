import HorizontalRule from 'components/HorizontalRule';
import React, { Component } from 'react';
import { get, isObject, isEmpty, values } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
import ConfirmationModal from 'components/V2/ConfirmationModal';

class RateBoostModal extends Component {
  state = {
    open: false,
    addRate: 5,
    payRate: this.props.shift.payRate || this.props.shift.event.payRate,
    rateTypeId: this.props.shift.payRate
      ? this.props.shift.rateTypeId
      : this.props.shift.event.rateTypeId,
    isRateIncreaseModalOpen: false,
  };

  RATES = [5, 10, 15, 20];

  toggle = () => {
    const { shift } = this.props;
    this.setState((prevState) => ({
      open: !prevState.open,
      addRate: 5,
      payRate: prevState.open ? 0 : shift.payRate || shift.event.payRate,
      rateTypeId: prevState.open ? 0 : shift.payRate ? shift.rateTypeId : shift.event.rateTypeId,
    }));
  };

  toggleRateIncreaseModal = () =>
    this.setState({ isRateIncreaseModalOpen: !this.state.isRateIncreaseModalOpen });

  onChangeRate(e) {
    this.setState({ addRate: e === '' ? '' : Number(e) });
  }

  calculateNewRate = () => {
    return this.state.payRate + (this.state.addRate || 0);
  };

  onSave = () => {
    if (!this.isValid()) {
      return;
    }

    if (!!this.showRateIncreaseModal()) {
      this.setState((prevState) => ({
        open: !prevState.open,
      }));
      this.toggleRateIncreaseModal();
      return;
    }

    const { shift } = this.props;
    const payload = {
      rate: this.calculateNewRate(),
      rateTypeId: this.state.rateTypeId,
      shiftId: shift.id,
      eventId: shift.event.id,
      boostedAt: moment.utc().format(),
    };
    this.props.onSave(payload);
    this.toggle();
  };

  isValid = () => {
    return this.state.addRate && this.state.addRate <= 100000;
  };

  showRateIncreaseModal = () => {
    const { shift } = this.props;
    const { payRate, addRate } = this.state;
    const jobs = Object.values(shift.jobs.byId);
    const jobsWithApplicants = jobs.filter((job) =>
      ['pending', 'waitlist', 'eo_accepted_at', 'confirmed', 'checked_in'].includes(
        job.currentState
      )
    );
    const hasApplicants = jobsWithApplicants.length > 0;
    const rateIncreased = jobsWithApplicants.every(
      (job) => job.payRate < Number(payRate) + addRate
    );

    return !!hasApplicants && !!rateIncreased && !this.state.isRateIncreaseModalOpen;
  };

  onContinueSave = (updateJobsPayRate) => {
    const { shift } = this.props;
    const payload = {
      rate: this.calculateNewRate(),
      rateTypeId: this.state.rateTypeId,
      shiftId: shift.id,
      eventId: shift.event.id,
      boostedAt: moment.utc().format(),
      updateJobsPayRate,
      shift,
    };
    this.props.onSave(payload);
    this.setState((prevState) => ({
      addRate: 5,
      payRate: prevState.open ? 0 : shift.payRate || shift.event.payRate,
      rateTypeId: prevState.open ? 0 : shift.payRate ? shift.rateTypeId : shift.event.rateTypeId,
    }));
    this.toggleRateIncreaseModal();
  };

  render() {
    const { payRate, rateTypeId, open, addRate } = this.state;
    return (
      <div className="rateBoostModal">
        <button
          className={classNames('plain-button', {
            'boost-shift': this.props.from === 'upcomingShifts',
            'modal-button': this.props.from === 'dashboard',
          })}
          onClick={this.toggle}
        >
          {this.props.children}
        </button>
        <Modal isOpen={open} toggle={this.toggle} className="rate-boost-modal-content">
          <ModalHeader className="header" toggle={this.toggle}>
            <div className="title">Boost Rate</div>
          </ModalHeader>
          <ModalBody>
            <h6>CURRENT RATE: ${payRate.toFixed(2)}</h6>
            <HorizontalRule dashed />
            <p>Boost Rate By</p>
            <div className="row">
              {this.RATES.map((rate, idx) => {
                return (
                  <div key={idx} className="col-md-3">
                    <div
                      className={`rate ${addRate === rate ? 'active' : ''}`}
                      onClick={() => this.setState({ addRate: rate })}
                    >
                      <p>$</p>
                      <h5>{rate}</h5>
                      {rateTypeId === 0 && <p>hr</p>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>OTHER:</p>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text rate-input">$</span>
                  </div>
                  <input
                    className="form-control rate-input"
                    type="number"
                    name="rate"
                    min="1"
                    max="100000"
                    step="1"
                    value={addRate}
                    placeholder="1"
                    onChange={(e) => this.onChangeRate(isObject(e) ? get(e, 'target.value') : e)}
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text rate-input">
                      {rateTypeId === 0 && 'hr'}
                      {rateTypeId === 1 && 'flat'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <HorizontalRule dashed />
            <h6>NEW BOOSTED RATE: ${this.calculateNewRate().toFixed(2)}</h6>
          </ModalBody>
          <ModalFooter className="actions">
            <button disabled={!this.isValid()} className="btn btn-success" onClick={this.onSave}>
              BOOST RATE!
            </button>
          </ModalFooter>
        </Modal>
        <ConfirmationModal
          isOpen={this.state.isRateIncreaseModalOpen}
          toggle={this.toggleRateIncreaseModal}
          title="Pay Rate Increase"
          body="Do you want to increase the pay for all shifts in this job, including those with applicants?"
          onSave={() => this.onContinueSave(true)}
          cancelText={
            <React.Fragment>
              <div>No, future</div>
              <div>applicants only</div>
            </React.Fragment>
          }
          confirmText={
            <React.Fragment>
              <div>Yes,</div>
              <div>all applicants</div>
            </React.Fragment>
          }
          onCancel={() => this.onContinueSave(false)}
        />
      </div>
    );
  }
}

export default RateBoostModal;
