import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import { clearV2Form } from 'redux/ducks/Post/actions';
import ActionBar from 'scenes/V2/Shared/ActionBar';
import { trackMixpanelPageView } from 'helpers/post';
import HorizontalRule from 'components/HorizontalRule';

class EventType extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventType');
    props.clearV2Form();
  }

  componentDidMount() {
    trackMixpanelPageView('create-event/type');
  }

  onSelect = (type) => () => {
    if (type === 'contract') {
      trackMixpanelPageView('LongTermForm');
    }
    this.props.onChangeProfile({
      name: 'eventType',
      value: type,
    });
  };

  onSaveAndContinue = () => {
    const { eventType } = this.props.profile;
    if (!eventType) return;

    if (eventType === 'per-diem') this.props.history.push('/v2/create-event/setting');
  };

  render() {
    const { eventType } = this.props.profile;

    return (
      <div className="eventType">
        <header>
          <div className="screen-title">What type of job are you hiring for?</div>
        </header>
        <main>
          <div className="row buttons">
            <div className="choice">
              <button
                className={`type-button ${eventType === 'per-diem' ? 'selected' : ''}`}
                onClick={this.onSelect('per-diem')}
              >
                <p className='event-type gotham-bold-font'>
                  Daily
                </p>
                <HorizontalRule dashed margin={0} />
                <p className='type-tip nimbus-regular-font'>
                  I need an athletic trainer for a few hours, a few days, or a recurring schedule.
                </p>
              </button>
            </div>
            <div className="choice">
              <a
                href="https://www.go4.io/contract-services-consultation-form-platform/ "
                rel="noopener noreferrer"
                target="_blank"
              >
                <button
                  className={`type-button ${eventType === 'contract' ? 'selected' : ''}`}
                  onClick={this.onSelect('contract')}
                >
                  <p className='event-type gotham-bold-font'>
                    Long-Term
                  </p>
                  <HorizontalRule dashed margin={0} />
                  <p className="type-tip nimbus-regular-font">
                    I need an athletic trainer on-site for a month or longer.
                  </p>
                </button>
              </a>
            </div>
          </div>
        </main>
        <ActionBar
          nextDisabled={() => !eventType}
          editMode={this.props.editMode}
          currentStep="type"
          backTo="/dashboard"
          onSaveAndContinue={this.onSaveAndContinue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.post.profile,
  authenticated: state.session.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeProfile: ({ name, value }) => dispatch(changeProfileField({ name, value })),
  clearV2Form: () => dispatch(clearV2Form()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventType);
