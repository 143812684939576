import { PaymentSerializer, UserSerializer } from 'services/serializers';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { get } from 'lodash';
import { MILITARY_FORMAT } from 'helpers/datetime';

export default class JobSerializer {
  static deserialize(job, location, athleticTrainers = []) {
    const result = {
      id: job.id,
      startTime: job.start_time,
      endTime: job.end_time,
      appliedDate: job.applied_date,
      currentState: job.current_state,
      shiftId: job.shift_id,
      userId: job.user_id,
      rateTypeId: job.rate_type_id,
      payRate: job.pay_rate,
      createdAt: job.created_at,
      expiresAt: job.eo_expires_at,
      cancelledAt: job.cancelled_at,
    };

    if (job.payment) {
      result['payment'] = PaymentSerializer.deserialize(job.payment);
    }

    if (job.user) {
      result['user'] = UserSerializer.deserializeHCT(job.user);
    } else if (job.user_id) {
      result['user'] = UserSerializer.deserializeHCT(
        athleticTrainers.find((at) => at.id == job.user_id)
      );
    }

    if (job.job_modification) {
      result['jobModification'] = JobSerializer.deserialize(job.job_modification);
    }

    return result;
  }

  static serialize(job, location) {
    const zipCode = get(location, 'address.zipCode') || get(location, 'zipCode');
    const tz = zipcode_to_timezone.lookup(zipCode) || moment.tz.guess();

    const result = {
      id: job.id,
      applied_date: job.appliedDate,
      end_time: moment.tz(job.endTime, MILITARY_FORMAT, tz).tz('UTC').format(),
      start_time: moment.tz(job.startTime, MILITARY_FORMAT, tz).tz('UTC').format(),
      shift_id: job.shiftId,
      state: job.state,
      user_id: job.userId,
      rate_type_id: job.rateTypeId,
      pay_rate: job.payRate,
    };

    return result;
  }
}
