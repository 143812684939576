import moment from 'moment-timezone';
import { get } from 'lodash';

export const formatDateTimeTz = (date, tz) => moment.tz(date, tz).format('MM/DD/YYYY  -  h:mmA');

export const formatTimeTz = (date, tz) => moment.tz(date, tz).format('h:mmA');

export const formatTableDateTz = (date, tz) => moment.tz(date, tz).format('MM/DD/YY');

export const formatExpirationDateTz = (date, tz) => moment.tz(date, tz).format('MM/DD @ h:mmA');

export const meridiemTo24 = (hour = '') => {
  const [time, meridiem] = hour.split(' ');
  const [hours, minutes] = time.split(':');

  if (!hours || !minutes) {
    return '';
  }

  if (meridiem === 'PM' && parseInt(hours) < 12) {
    return `${parseInt(hours) + 12}:${minutes}`;
  }

  if (meridiem === 'AM' && parseInt(hours) === 12) {
    return `00:${minutes}`;
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};

export const hour24ToMeridiem = (hour = '', options = {}) => {
  const [hours, minutes] = hour.split(':');
  const space = get(options, 'space', true);

  if (!hours || !minutes) {
    return '';
  }

  if (parseInt(hours) === 0) {
    return `12:${minutes}${space ? ' ' : ''}AM`;
  }

  if (parseInt(hours) === 12) {
    return `12:${minutes}${space ? ' ' : ''}PM`;
  }

  if (parseInt(hours) > 12) {
    return `${parseInt(hours) - 12}:${minutes}${space ? ' ' : ''}PM`;
  }

  return `${parseInt(hours)}:${minutes}${space ? ' ' : ''}AM`;
};

// MM/DD/YY | HH:MM AM/PM - HH:MM AM/PM
export const shiftDateFormat = (shift) => {
  const startTime = moment(shift.startTime);
  const endTime = moment(shift.endTime);

  if (startTime.isSame(endTime, 'date'))
    return `${startTime.format('MM/DD/YY')} | ${startTime.format('h:mm A')} - ${endTime.format(
      'h:mmA'
    )}`;

  return `${startTime.format('MM/DD/YY')} ${startTime.format('h:mm A')} - ${endTime.format(
    'MM/DD/YY'
  )} ${endTime.format('h:mm A')}`;
};

export const ZONE_ABBREVIATIONS = {
  'America/New_York': 'ET',
  'America/Chicago': 'CT',
  'America/Denver': 'MT',
  'America/Los_Angeles': 'PT',
  'America/Kentucky/Louisville': 'ET',
  'America/Indiana/Indianapolis': 'ET',
  'America/Detroit': 'ET',
  'America/Boise': 'MT',
  'America/Phoenix': 'MT',
  'America/Anchorage': 'AKT',
  'Pacific/Honolulu': 'HT',
  'America/Indiana/Knox': 'CT',
  'America/Indiana/Winamac': 'ET',
  'America/Indiana/Vevay': 'ET',
  'America/Indiana/Marengo': 'ET',
  'America/Indiana/Vincennes': 'ET',
  'America/Indiana/Tell_City': 'CT',
  'America/Indiana/Petersburg': 'ET',
  'America/Menominee': 'CT',
  'America/Shiprock': 'MT',
  'America/Nome': 'AKT',
  'America/Juneau': 'AKT',
  'America/Kentucky/Monticello': 'ET',
  'America/North_Dakota/Center': 'CT',
  'America/Yakutat': 'AKT',
};

export const MILITARY_FORMAT = 'MM-DD-YYYY HH:mm';
