import ApiService from '../ApiService';
import EventSerializer from '../serializers/v2/EventSerializer';
import DashboardSerializer from 'services/serializers/v2/DashboardSerializer';
import { filterCancelledShifts } from 'helpers/post';

class EventService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/events';
    this.eventOperatorId = session.currentUser.id;
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`, (event) =>
      EventSerializer.deserialize(event, event.athletic_trainers)
    );
  }

  applicants(params) {
    return super.search(`/v2/dashboard/${this.url}.json`, params, (response) => {
      return {
        total: response.total,
        per_page: response.per_page,
        total_pages: response.total_pages,
        current_page: response.current_page,
        events: DashboardSerializer.applicantsDeserializer(response),
        athletic_trainers: response.athletic_trainers.map((at) =>
          DashboardSerializer.deserializeUser(at)
        ),
        job_count: response.jobs?.filter((job) => job.current_state === 'pending').length || 0,
      };
    });
  }

  create(event) {
    return super.post(
      `${this.url}.json`,
      EventSerializer.serialize(event, this.eventOperatorId),
      (event) => EventSerializer.deserialize(event)
    );
  }

  update(event, cancelledShifts = [], shiftIds) {
    return super.patch(
      `${this.url}/${event.id || event.details.id}.json`,
      EventSerializer.serialize(event, this.eventOperatorId, shiftIds),
      (event) => EventSerializer.deserialize(filterCancelledShifts(event, cancelledShifts))
    );
  }

  changeRate(payload) {
    const { eventId } = payload;
    return super.patch(
      `${this.url}/${eventId}.json`,
      EventSerializer.serializeShiftRate(payload),
      () => true
    );
  }

  createOrUpdate(event, cancelledShifts = [], shiftIds) {
    if (!!event.details.id) {
      return this.update(event, cancelledShifts, shiftIds);
    } else {
      return this.create(event);
    }
  }

  deleteShiftDocument({ eventId, documentId }) {
    return super.delete(
      `${this.url}/${eventId}/documents/${documentId}/destroy_shift_document.json`,
      () => ({ eventId, documentId })
    );
  }

  jobsMetadata(params) {
    return super.search(`/v2/dashboard/${this.url}/jobs_metadata.json`, params, (response) =>
      response.jobs.map((job) => ({
        id: job.id,
        overlappingWithAnotherJob: job.overlapping_with_another_job,
        appliedToAllShiftsLocation: job.applied_to_all_shifts_location_ids,
      }))
    );
  }
}

export default EventService;
