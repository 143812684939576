import * as postActions from '../actions';
import * as actions from './actions';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { addErrorsToShift } from './validators';
import { map, cloneDeep, pick, get } from 'lodash';
import * as locationActions from '../Locations/actions';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { getTimezone, isEndTimeAfterNow } from 'helpers/post';
import { MILITARY_FORMAT } from 'helpers/datetime';

// Reducer Functions
// ========================================================

const shiftMappingReducer = (updateFn: Function) => {
  return (state: ShiftsType, payload) => {
    return state
      .map((shift, idx) => {
        if (payload && idx === payload.index) {
          return updateFn(shift, payload);
        } else {
          return shift;
        }
      })
      .map((shift) => addErrorsToShift({ shift }))
      .flatMap(addIdx);
  };
};

export const initShiftsReducer = (state, event) => {
  if (!Array.isArray(event.shifts)) return state;

  return map(event.shifts, (shift) => {
    const zipCode = shift.eventLocation.address.zipCode;
    const tz = zipcode_to_timezone.lookup(zipCode) || momentTz.tz.guess();
    const locationId = shift.eventLocation.id;

    const form = new Shift({ locationId, zipCode });
    const startTime = shift.startTime
      ? momentTz.tz(shift.startTime, tz).format('MM-DD-YYYY HH:mm')
      : '';
    const endTime = shift.endTime ? momentTz.tz(shift.endTime, tz).format('MM-DD-YYYY HH:mm') : '';
    const startDate = shift.startTime ? momentTz.tz(shift.startTime, tz).format('MM-DD-YYYY') : '';
    const endDate = shift.endTime ? momentTz.tz(shift.endTime, tz).format('MM-DD-YYYY') : '';
    const start = shift.startTime ? momentTz.tz(shift.startTime, tz).format('HH:mm') : '';
    const end = shift.endTime ? momentTz.tz(shift.endTime, tz).format('HH:mm') : '';

    const timeAttrs = {
      startTime,
      endTime,
      startDate,
      endDate,
      start,
      end,
      capacity: shift.shiftCapacity,
    };

    Object.assign(
      form,
      cloneDeep(
        pick(shift, [
          'id',
          'emergencyActionPlanId',
          'reportToContacts',
          'startTime',
          'endTime',
          'shiftCapacity',
          'countType',
          'countValue',
          'schedule',
          'approxHours',
          'notes',
          'responsibilities',
          'contractInfo',
          'instructions',
          'payRate',
          'rateTypeId',
          'cancelled',
          'cancelReason',
          'locationCancelled',
          'boostedAt',
          'jobs',
          'isMultiDay',
        ])
      ),
      timeAttrs
    );

    return form;
  })
    .map((shift) => addErrorsToShift({ shift }))
    .map(addIdx);
};

const shiftAddedReducer = (state, payload) => {
  return [...state, new Shift(payload)].map(addIdx).map((shift) => addErrorsToShift({ shift }));
};

const mapSavedShifts = (state, payload) => {
  const cancelledShiftIds = state.filter((s) => s.cancelled).map((s) => s.id);
  return payload.shifts
    .filter((s) => !cancelledShiftIds.includes(s.id))
    .flatMap(addIdx)
    .map((shift) => addErrorsToShift({ shift }));
};

const generateRepeatedShiftsReducer = (state, payload) => {
  const { locationId, zipCode, schedule, capacity } = payload;
  const { weekDays, startDate, endDate, start, end } = schedule;
  const startMoment = moment(startDate, MILITARY_FORMAT);
  const endMoment = moment(endDate, MILITARY_FORMAT);
  const shifts = state;

  while (startMoment.isSameOrBefore(endMoment)) {
    if (weekDays.includes(startMoment.format('dddd'))) {
      const endDate = startMoment.clone();
      const startMomentString = startMoment.format('MM-DD-YYYY');

      if (
        moment(`${startMomentString} ${start}`, MILITARY_FORMAT).isAfter(
          moment(`${startMomentString} ${end}`, MILITARY_FORMAT)
        )
      )
        endDate.add(1, 'days').format('MM-DD-YYYY');

      const schedule = {
        startDate: startMoment.format('MM-DD-YYYY'),
        endDate: endDate.format('MM-DD-YYYY'),
        start,
        end,
      };

      shifts.push(new Shift({ locationId, zipCode, capacity, schedule }));
    }
    startMoment.add(1, 'days');
  }

  return shifts.flatMap(addIdx);
};

const shiftRemovedReducer = (state, payload) =>
  state
    .reduce((acc, shift) => {
      if (shift.idx !== payload.index) return [...acc, shift];
      if (!shift.id) return acc;

      return [...acc, { ...shift, cancelled: true, cancelReason: payload.reason }];
    }, [])
    .flatMap(addIdx);

const reloadFormReducer = (state, payload) => {
  return (payload?.shifts ? [...payload.shifts] : state).flatMap(addIdx);
};

const cancelMultipleShiftsReducer = (state, { indexes }) =>
  state.reduce((acc, shift) => {
    if (!shift.id && indexes.includes(shift.idx)) return acc;

    return [...acc, indexes.includes(shift.idx) ? { ...shift, cancelled: true } : shift];
  }, []);

const distributeShiftRateReducer = (state, payload) => {
  const { rate, rateTypeId, locationId } = payload;
  return state.map((shift) => {
    const tz = getTimezone(shift);

    //  ignore if shift is cancelled, locationId is different, or end time is before now
    if (
      shift.cancelled ||
      shift.locationId !== locationId ||
      !isEndTimeAfterNow(momentTz.tz(shift.endTime, MILITARY_FORMAT, tz).format(), tz)
    )
      return shift;

    shift.payRate = rate;
    shift.rateTypeId = rateTypeId;

    return shift;
  });
};

export const computeStart = (shift, value) => {
  const startDate = moment(shift.startDate + ' ' + value, MILITARY_FORMAT);
  const endDate = moment(shift.endDate + ' ' + shift.end, MILITARY_FORMAT).isValid()
    ? moment(shift.endDate + ' ' + shift.end, MILITARY_FORMAT)
    : moment(shift.endDate, MILITARY_FORMAT);

  if (Math.abs(startDate.diff(endDate, 'minutes')) > 24 * 60) endDate.subtract(1, 'days');
  if (endDate.isSameOrBefore(startDate)) endDate.add(1, 'days');

  shift.endTime = endDate.format('MM-DD-YYYY') + ' ' + shift.end;
  shift.endDate = endDate.format('MM-DD-YYYY');
  shift.startTime = shift.startDate + ' ' + value;
};

export const computeEnd = (shift, value) => {
  const startDate = moment(shift.startDate + ' ' + shift.start, MILITARY_FORMAT);
  const endDate = moment(shift.endDate + ' ' + value, MILITARY_FORMAT);

  if (Math.abs(startDate.diff(endDate, 'minutes')) > 24 * 60) endDate.subtract(1, 'days');
  if (endDate.isSameOrBefore(startDate)) endDate.add(1, 'days');

  shift.endTime = endDate.format('MM-DD-YYYY') + ' ' + value;
  shift.endDate = endDate.format('MM-DD-YYYY');
};

export const computeStartDate = (shift, value) => {
  const endDate = moment(value + ' ' + shift.end, MILITARY_FORMAT).isValid()
    ? moment(value + ' ' + shift.end, MILITARY_FORMAT)
    : moment(value, MILITARY_FORMAT);

  if (endDate.isSameOrBefore(moment(value + ' ' + shift.start, MILITARY_FORMAT)) && !!shift.end)
    endDate.add(1, 'days');

  shift.endDate = endDate.format('MM-DD-YYYY');
  shift.endTime = endDate.format('MM-DD-YYYY') + ' ' + shift.end;
  shift.startTime = value + ' ' + shift.start;
};

export const computeEndDate = (shift, value) => {};

export const computeMultiDayStart = (shift, value) => {
  shift['startTime'] = shift.startDate + ' ' + value;
};

export const computeMultiDayEnd = (shift, value) => {
  shift['endTime'] = shift.endDate + ' ' + value;
};

export const computeMultiDayStartDate = (shift, value) => {
  shift['startTime'] = value + ' ' + shift.start;
};

export const computeMultiDayEndDate = (shift, value) => {
  shift['endTime'] = value + ' ' + shift.end;
};

const changeShiftReducer = shiftMappingReducer((shift, payload) => {
  const updateJobsPayRate = payload.updateJobsPayRate || shift.updateJobsPayRate;
  return { ...shift, ...payload.shift, updateJobsPayRate };
});

const changeShiftFieldReducer = shiftMappingReducer((shift, { name, value }) => {
  const { isMultiDay } = shift;
  const fieldComputations = {
    start: isMultiDay ? computeMultiDayStart : computeStart,
    end: isMultiDay ? computeMultiDayEnd : computeEnd,
    startDate: isMultiDay ? computeMultiDayStartDate : computeStartDate,
    endDate: isMultiDay ? computeMultiDayEndDate : computeEndDate,
  };

  if (fieldComputations[name]) fieldComputations[name](shift, value);

  shift[name] = value;
  return shift;
});

const locationDeletedReducer = (state, payload) => {
  const { locationIndex, locationId, reason } = payload;
  return state
    .map((shift) => {
      if (shift.locationId === locationId || shift.locationId === locationIndex) {
        shift.cancelled = true;
        shift.cancelReason = reason;
        shift.locationCancelled = true;
      }
      return shift;
    })
    .flatMap(addIdx);
};

// Reducer
// ========================================================
const DEFAULT_STATE = [];

export type ShiftsType = typeof DEFAULT_STATE;

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case actions.SHIFT_ADDED:
      return shiftAddedReducer(state, action.payload);
    case actions.SHIFT_REMOVED:
      return shiftRemovedReducer(state, action.payload);
    case actions.SHIFT_FIELD_CHANGED:
      return changeShiftFieldReducer(state, action.payload);
    case actions.SHIFT_CHANGED:
      return changeShiftReducer(state, action.payload);
    case actions.REMOVE_MULTIPLE_SHIFTS:
      return cancelMultipleShiftsReducer(state, action.payload);
    case actions.REPEAT_SHIFTS:
      return generateRepeatedShiftsReducer(state, action.payload);
    case actions.DISTRIBUTE_RATE:
      return distributeShiftRateReducer(state, action.payload);

    case postActions.V2_CLEAR_FORM:
      return DEFAULT_STATE;
    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return mapSavedShifts(state, action.payload);
    case postActions.INITIALIZE_POST:
    case postActions.DUPLICATE_POST:
      return initShiftsReducer(state, action.payload);

    case locationActions.DELETE_LOCATION:
      return locationDeletedReducer(state, action.payload);
    default:
      return state;
  }
}

export interface ShiftType {
  locationId: number;
  id: ?number;
  idx: ?number;
  start: string;
  end: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  capacity: number;
  addressSearchResults: any[];
  isSearching: boolean;
  emergencyActionPlanId: ?number;
  jobs: any[];
  cancelled: boolean;
  cancelReason: string;
  locationCancelled: boolean;
  errorMessages: {
    [messageName: string]: string,
  };
  countType: ?string;
  countValue: ?number;
  notes: ?string;
  responsibilities: ?string;
  instructions: ?string;
  contractInfo: { [key: string]: any };
  reportToContacts: any[];
  payRate: number;
  rateTypeId: number;
  zipCode: string;
}

export class Shift implements ShiftType {
  id = null;
  idx = null;
  addressSearchResults = [];
  isSearching = false;
  emergencyActionPlanId = null;
  jobs = [];
  cancelled = false;
  cancelReason = '';
  locationCancelled = false;
  errorMessages = {
    payRate: 'Pay rate is required to be between $0-$100,000',
  };
  countType = 'noFields';
  approxHours = null;
  notes = '';
  responsibilities = '';
  instructions = '';
  contractInfo = {};
  reportToContacts = [];
  payRate = '';
  rateTypeId = '0';
  details = {};
  startDate = '';
  endDate = '';
  start = '';
  end = '';
  startTime = '';
  endTime = '';
  capacity = 1;
  isMultiDay = false;

  constructor(shift) {
    this.locationId = get(shift, 'locationId');
    this.startDate = get(shift, 'schedule.startDate');
    this.endDate = get(shift, 'schedule.endDate');
    this.start = get(shift, 'schedule.start', '');
    this.end = get(shift, 'schedule.end', '');
    this.zipCode = get(shift, 'zipCode');
    this.capacity = get(shift, 'capacity', 1);
    this.details = get(shift, 'details', {});
    this.rateTypeId = get(shift, 'rateTypeId', 0).toString();
    this.payRate = get(shift, 'payRate', '');
    this.startTime = this.startDate + ' ' + this.start;
    this.endTime = this.endDate + ' ' + this.end;
  }
}

const addIdx = (shift, idx) => {
  shift.idx = idx;
  return shift;
};
