import React, { Component } from 'react';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import { formatTableDateTz, formatTimeTz } from 'helpers/datetime';
import zipcode_to_timezone from 'zipcode-to-timezone';
import JobsForShift from 'components/V2/JobsForShift';
import { UncontrolledTooltip } from 'reactstrap';
import { SHOW_TOOLTIP_COLUMNS } from 'helpers/dashboard';

class ShiftJobComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
    };
    this.columnRefs = {};

    SHOW_TOOLTIP_COLUMNS.shiftsToReview.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkAllOverflows();

      this.resizeObserver = new ResizeObserver(() => {
        this.checkAllOverflows();
      });

      SHOW_TOOLTIP_COLUMNS.shiftsToReview.forEach((column) => {
        const element = this.columnRefs[column].current;
        if (element) {
          this.resizeObserver.observe(element);
        }
      });
    }, 0);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  checkAllOverflows = () => {
    const isOverflowing = {};

    SHOW_TOOLTIP_COLUMNS.shiftsToReview.forEach((column) => {
      const element = this.columnRefs[column].current;
      if (element) {
        isOverflowing[column] = element.scrollWidth > element.clientWidth;
      }
    });

    this.setState({ isOverflowing });
  };

  render() {
    const { shifts, eventTitle } = this.props;

    return (
      <div className="applicants-list">
        {shifts.map((shift, idx) => {
          const shiftLocation = shift.eventLocation;
          const tz =
            zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();
          const jobs = shift.jobs;

          if (!jobs.length) return null;

          const fullShiftInfo = {
            jobName: `Shift ${idx + 1}`,
            startTime: (
              <>
                Date: <span>{formatTableDateTz(shift.startTime, tz)}</span>
              </>
            ),
            time: (
              <>
                Time:{' '}
                <span>{`${formatTimeTz(shift.startTime, tz)} - ${formatTimeTz(
                  shift.endTime,
                  tz
                )}`}</span>
              </>
            ),
            location: (
              <>
                Location:{' '}
                <span>{`${shiftLocation.address.city}, ${shiftLocation.address.state}`}</span>
              </>
            ),
            venue: (
              <>
                Venue: <span>{shiftLocation.name}</span>
              </>
            ),
          };

          return (
            <React.Fragment key={shift.id}>
              <div className="shift-header">
                <div id={`shift-jobName-${shift.id}`} ref={this.columnRefs['jobName']}>
                  {fullShiftInfo.jobName}
                  {this.state.isOverflowing.jobName && (
                    <UncontrolledTooltip target={`shift-jobName-${shift.id}`}>
                      {fullShiftInfo.jobName}
                    </UncontrolledTooltip>
                  )}
                </div>
                <div id={`shift-startTime-${shift.id}`} ref={this.columnRefs['startTime']}>
                  {fullShiftInfo.startTime}
                  {this.state.isOverflowing.startTime && (
                    <UncontrolledTooltip target={`shift-startTime-${shift.id}`}>
                      {fullShiftInfo.startTime}
                    </UncontrolledTooltip>
                  )}
                </div>
                <div id={`shift-time-${shift.id}`} ref={this.columnRefs['time']}>
                  {fullShiftInfo.time}
                  {this.state.isOverflowing.time && (
                    <UncontrolledTooltip target={`shift-time-${shift.id}`}>
                      {fullShiftInfo.time}
                    </UncontrolledTooltip>
                  )}
                </div>
                <div id={`shift-location-${shift.id}`} ref={this.columnRefs['location']}>
                  {fullShiftInfo.location}
                  {this.state.isOverflowing.location && (
                    <UncontrolledTooltip target={`shift-location-${shift.id}`}>
                      {fullShiftInfo.location}
                    </UncontrolledTooltip>
                  )}
                </div>
                <div id={`shift-venue-${shift.id}`} ref={this.columnRefs['venue']}>
                  {fullShiftInfo.venue}
                  {this.state.isOverflowing.venue && (
                    <UncontrolledTooltip target={`shift-venue-${shift.id}`}>
                      {fullShiftInfo.venue}
                    </UncontrolledTooltip>
                  )}
                </div>
              </div>
              <JobsForShift
                jobs={sortBy(jobs, 'appliedDate')}
                shift={shift}
                from="applicants"
                eventTitle={eventTitle}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default ShiftJobComponent;
