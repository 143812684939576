import React, { Component } from 'react';

class LongShiftAlert extends Component {
  render() {
    return (
      <div className="alert-long-shift">
        <div style={{ display: 'flex' }}>
          <i
            className="material-icons ml-1"
            style={{ fontSize: 18, color: '#fee02d', marginRight: 3 }}
            id={'shift-longer-than-8-hours'}
          >
            info
          </i>
        </div>
        The shift is longer than 8 hours; confirm the Start/End Times are accurate.
      </div>
    );
  }
}

export default LongShiftAlert;
