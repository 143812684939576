import React from 'react';
import { connect } from 'react-redux';

export const ConfirmationBanner = ({ user, ...props }) => {
  if (!props.authenticated || user.confirmedAt || !user.confirmationSentAt) return null;

  const onClickBanner = () => {};

  return (
    <div className="confirmation-banner">
      <div color="info" className="message" onClick={onClickBanner}>
        <span className="message-text">
          Action Required: Please verify your account by checking your email {user.email} for the
          verification link.
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.session.currentUser,
    authenticated: state.session.authenticated,
  };
};

export default connect(mapStateToProps)(ConfirmationBanner);
