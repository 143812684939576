import moment from 'moment-timezone';
import { get, isEmpty } from 'lodash';

export const getPaymentType = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return 'bank_account';

  return get(payment, 'source.object', null);
};

export const hasPaymentToPublish = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return true;

  return !!payment && !!payment.source && !!payment.source.id && !!payment.loadedSource;
};

export const hasPaymentToUpdate = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return false;

  return !!payment && !!payment.source && !!payment.source.id && !!payment.loadedSource;
};

export const hasToVerifyMicroDeposits = (payment) => {
  return !!Object.keys(payment).includes('publicToken') && !!payment.publicToken;
};

export const getErrorMessage = (message) => {
  return message.replace(/\(.*?\)/g, '').trim();
};

export const apply50percentagePerJob = (payableAmount) => {
  if (!payableAmount) return 0;

  return payableAmount * 0.5;
};

export const apply25percentagePerJob = (payableAmount) => {
  if (!payableAmount) return 0;

  return payableAmount * 0.25;
};

export const totalHours = (job) => {
  if (isEmpty(job) || !job.startTime || !job.endTime) return 0;

  const startTime = moment(job.startTime);
  const endTime = moment(job.endTime);
  return moment.duration(endTime.diff(startTime)).as('hours');
};

export const applyPenalty = (job, tz) => {
  const createdAt = new Date().toLocaleString('en-US', { timeZone: tz });
  const startTime = job.startTime;
  const totalPay = get(job, 'payRate', 0) * totalHours(job);

  if (
    moment
      .tz(startTime, tz)
      .isBetween(moment.tz(createdAt, tz), moment.tz(createdAt, tz).add(24, 'hours'))
  ) {
    return apply50percentagePerJob(totalPay);
  } else if (
    moment
      .tz(startTime, tz)
      .isBetween(
        moment.tz(createdAt, tz).add(24, 'hours'),
        moment.tz(createdAt, tz).add(72, 'hours')
      )
  ) {
    return apply25percentagePerJob(totalPay);
  } else if (moment.tz(createdAt, tz).add(72, 'hours').isBefore(moment.tz(startTime, tz))) {
    return 0;
  }
};
